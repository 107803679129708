<template>
  <div>
  <h2>Marching Band Drill Writer</h2>
    <p>
      The Marching Band Drill Writing App automates the creation of drill formations for marching bands,
      ensuring precise placement of band members on a field grid. Drill writing involves mapping intricate
      movements that synchronize with music, requiring meticulous attention to detail and accuracy. This project
      is based on a grid system that overlays the standard football field where it will take 8 22.5-inch steps
      to go 5 yards.
    </p>

    <section class="keyFeatures">
      <div class="col-sm-12 col-md-5">
        <h4>Key Features</h4>
        <ul>
          <li>Automates the creation of drill formations for marching bands, ensuring precise placement of band members on a field grid</li>
          <li>Based on a grid system that overlays the standard football field, using 8 22.5-inch steps to cover 5 yards</li>
        </ul>
      </div>
      <div class="col-md-5">
        <h4>Key Technologies</h4>
        <ul>
          <li>Angular</li>
          <li>TypeScript</li>
          <li>SCSS (Sass)</li>
          <li>Firebase</li>
          <li>Node.js</li>
          <li>Express</li>
          <li>Postgresql</li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DrillWriterComponent',
}
</script>


