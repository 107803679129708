<template>
 <div>
  <h2 class="projectDetails">BigHand SmartTime</h2>
  <p>BigHand SmartTime streamlines time capture and entry for law firms, enhancing efficiency and accuracy. It helps increase billable hours, accelerate payment cycles, and improve client trust through transparent, compliant invoicing.</p>

  <section class="keyFeatures">
  <div class="col-sm-12 col-md-5">
    <h4>Key Features</h4>
    <ul>
      <li>Automated Time Capture: Efficient time recording.</li>
      <li>Streamlined Time Entry: Pre-filled entries for diverse activities.</li>
      <li>Easy Approval & Edits: Swift manager approvals and corrections.</li>
      <li>Transparency for Clients: Clear, compliant invoicing.</li>
      <li>Flexibility & Mobility: Seamless desktop and mobile integration.</li>
    </ul>
  </div>
  <div class="col-med-5">
   <h4>Key Technologies</h4>
    <ul>
        <li>C#</li>
        <li>.NET</li>
        <li>ASP.NET MVC</li>
        <li>SQL</li>
        <li>JavaScript</li>
        <li>jQuery</li>
        <li>CSS</li>
        <li>Kendo UI</li>
    </ul>
  </div>
  </section>

</div>

</template>

<script>
export default {
  name: 'SmartTimeComponent',
}
</script>

<style scoped>

  
</style>




