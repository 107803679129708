<template>
<div>
  <div>
    <h2 class="projectDetails">Vegas Beal Finder</h2>
    <p>
      I recently rewrote a Las Vegas deal finder app for a friend, migrating it to a new hosting platform. The app, built using Aurelia.js for frontend development, and underwent a complete overhaul. I utilized SCSS for styling, enhancing the app's visual appeal and responsiveness. The migration involved optimizing performance and ensuring seamless functionality across the new platform, offering users an improved experience in discovering and accessing Las Vegas deals.
    </p>
  </div>

<div class="keyFeatures">
  <div class="col-sm-12 col-md-5">
    <h4>Key Features</h4>
    <ul>
      <li>Rewrote a Las Vegas deal finder app, migrating it to a new hosting platform</li>
      <li>Utilized Aurelia.js for frontend development, providing a modern and efficient user interface</li>
      <li>Implemented SCSS for styling, enhancing the app's visual appeal and responsiveness</li>
      <li>Optimized performance and ensured seamless functionality across the new platform</li>
      <li>Offered users an improved experience in discovering and accessing Las Vegas deals</li>
    </ul>
  </div>

  <div class="col-md-5">
    <h4>Key Technologies</h4>
    <ul>
      <li>Node.js</li>
      <li>Aurelia.js</li>
      <li>SCSS (Sass)</li>
      <li>Webpack</li>
      <li>HTML5 & CSS3</li>
      <li>Git</li>
    </ul>
  </div>
</div>

</div>
</template>

<script>
export default {
  name: 'VdfComponent',
}
</script>

<style scoped>

</style>




